import React from "react";

import GridSmallTextRight from "../GridSmallTextRight";
import LogoBar from "../../Logobar";

import img1 from "../../../static/images/route-screen/mosaik/vorteile/mosaik-vorteile-1.png";
import img2 from "../../../static/images/route-screen/mosaik/vorteile/mosaik-vorteile-2.png";
import img3 from "../../../static/images/route-screen/mosaik/vorteile/mosaik-vorteile-3.png";
import img4 from "../../../static/images/route-screen/mosaik/vorteile/mosaik-vorteile-4.png";
import img5 from "../../../static/images/route-screen/mosaik/vorteile/mosaik-vorteile-5.png";
import img6 from "../../../static/images/route-screen/mosaik/vorteile/mosaik-vorteile-6.png";
import img7 from "../../../static/images/route-screen/mosaik/vorteile/mosaik-vorteile-7.png";
import img8 from "../../../static/images/route-screen/mosaik/vorteile/mosaik-vorteile-8.png";
import img9 from "../../../static/images/route-screen/mosaik/vorteile/mosaik-vorteile-9.png";

function MosaikVorteile() {
  return (
    <>
      <GridSmallTextRight
        img1={img1}
        img2={img2}
        img3={img3}
        img4={img4}
        img5={img5}
        img6={img6}
        img7={img7}
        img8={img8}
        img9={img9}
      >
        <span className="mb-4">Sie wollen richtig kreativ werden und einzigartige Räume auch aus komplexen Gegebenheiten schaffen?</span>
        <span>Mit unseren vielfältigen Mosaiken aus Glas oder Naturstein haben Sie jetzt noch mehr Möglichkeiten.</span>
      </GridSmallTextRight>

      <LogoBar
        brandNames={["intermatex", "sicis", "kiesel"]}
      />
    </>
  );
}

export default MosaikVorteile;
