import React, { Component } from "react";
import { Route, Link, Switch, Redirect, withRouter } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";

import { Element } from "react-scroll";

import caret from "../static/images/icons/caret-warning.svg";
import xIcon from "../static/images/icons/x-icon.svg";

import Default from "./RouteScreens/Default";
import FeinsteinWohnbereich from "./RouteScreens/feinstein/FeinsteinWohnbereich";
import FeinsteinBader from "./RouteScreens/feinstein/FeinsteinBader";
import FeinsteinBodenbelage from "./RouteScreens/feinstein/FeinsteinBodenbelage";
import FeinsteinSchwimmbader from "./RouteScreens/feinstein/FeinsteinSchwimmbader";
import MarmorWohnbereich from "./RouteScreens/marmor/MarmorWohnbereich";
import MarmorBader from "./RouteScreens/marmor/MarmorBader";
import MarmorBodenbelage from "./RouteScreens/marmor/MarmorBodenbelage";
import MarmorSchwimmbader from "./RouteScreens/marmor/MarmorSchwimmbader";
import VolimeaVorteile from "./RouteScreens/volimea/VolimeaVorteile";
import VolimeaFarbtone from "./RouteScreens/volimea/VolimeaFarbtone";
import VolimeaOberflachentechniken from "./RouteScreens/volimea/VolimeaOberflachentechniken";
import MosaikVorteile from "./RouteScreens/mosaik/MosaikVorteile";
import MosaikBeispiele from "./RouteScreens/mosaik/MosaikBeispiele";

import Jobs from "./RouteScreens/Jobs";
import Impressum from "./RouteScreens/Impressum";
import Datenschutz from "./RouteScreens/Datenschutz";

class RouteScreenContainer extends Component {
  capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

  render() {
    let routes =
      this.props.location.pathname !== "/"
        ? this.props.location.pathname.split("/").filter(el => !!el)
        : null;

    return (
      <div className="mx-0">
        <div className="d-flex align-items-center bg-dark h-80px text-warning text-zilla-slab font-size-24 rounded-bottom px-4 mb-4">
          {routes !== null
            ?
              (<span>
                {this.capitalize(
                  routes[0] === 'marmor_granit'
                    ? "marmor & granit"
                    : routes[0] === "volimea_wandbeschichtung"
                    ? "Volimea\u2122 Wandbeschichtung"
                    : routes[0]
                )}
                {routes.slice(1).map(el => (
                  <span className="font-weight-bold ml-2" key={el}>
                    <img src={caret} alt="Caret" className="px-1 mr-2" />
                    {this.capitalize(
                      el === "bader"
                        ? "bäder"
                        : el === "kuchen"
                        ? "küchen"
                        : el === "boden"
                        ? "böden"
                        : el
                    )}
                  </span>
                ))}
              </span>)
            :
              (<span>Hallo, liebe Besucher!</span>)
          }

          <Link to={"/"} className="d-none close-route-screen ml-auto mr-2px">
            <img src={xIcon} width={24} alt="X icon" />
          </Link>
        </div>

        <Element name="route-screen-container">
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
            className="switch-wrapper"
          >
            <Route path="/" exact component={Default} />

            {/* Feinstein */}
            <Route path="/feinstein/wohnbereich" exact component={FeinsteinWohnbereich} />
            <Route path="/feinstein/bader" exact component={FeinsteinBader} />
            <Route path="/feinstein/bodenbelage" exact component={FeinsteinBodenbelage} />
            <Route path="/feinstein/schwimmbader" exact component={FeinsteinSchwimmbader} />

            {/* Marmor & Granit */}
            <Route path="/marmor_granit/wohnbereich" exact component={MarmorWohnbereich} />
            <Route path="/marmor_granit/bader" exact component={MarmorBader} />
            <Route path="/marmor_granit/bodenbelage" exact component={MarmorBodenbelage} />
            <Route path="/marmor_granit/schwimmbader" exact component={MarmorSchwimmbader} />

            {/* Volimea Wandbeschichtung */}
            <Route path="/volimea_wandbeschichtung/vorteile" exact component={VolimeaVorteile} />
            <Route path="/volimea_wandbeschichtung/farbtone" exact component={VolimeaFarbtone} />
            <Route path="/volimea_wandbeschichtung/oberflachentechniken" exact component={VolimeaOberflachentechniken} />

            {/* Mosaik */}
            <Route path="/mosaik/vorteile" exact component={MosaikVorteile} />
            <Route path="/mosaik/beispiele" exact component={MosaikBeispiele} />

            <Route path="/impressum" exact component={Impressum} />
            <Route path="/jobs" exact component={Jobs} />
            <Route path="/datenschutz" exact component={Datenschutz} />

            <Redirect to="/" />
          </AnimatedSwitch>
        </Element>
      </div>
    );
  }
}

export default withRouter(RouteScreenContainer);
