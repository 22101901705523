import React from "react";

import cercom from "../static/images/brands/gray/cercom.png";
import cir from "../static/images/brands/gray/cir.png";
import gutjahr from "../static/images/brands/gray/gutjahr.png";
import intermatex from "../static/images/brands/gray/intermatex.png";
import kiesel from "../static/images/brands/gray/kiesel.png";
import mirage from "../static/images/brands/gray/mirage.png";
import saloni from "../static/images/brands/gray/saloni.png";
import serenissima from "../static/images/brands/gray/serenissima.png";
import sicis from "../static/images/brands/gray/sicis.png";
import tercocer from "../static/images/brands/gray/tercocer.png";
import volimea from "../static/images/brands/gray/volimea.png";
import wohnrausch from "../static/images/brands/gray/wohnrausch.png";

const BRANDS = [
  { name: "Mirage", img: mirage, href: "https://www.mirage.it/en/"},
  { name: "Saloni", img: saloni, href: "https://www.saloni.com/de"},
  { name: "Serenissima", img: serenissima, href: "https://www.serenissima.re.it/en/"},
  { name: "Cercom", img: cercom, href: "https://www.cercomceramiche.it/it/"},
  { name: "Cir", img: cir, href: "https://www.cir.it/it/"},
  { name: "Tercocer", img: tercocer, href: "http://www.tercocer.com/en/"},
  { name: "Wohnrausch", img: wohnrausch, href: "https://www.wohnrausch.net/"},
  { name: "Volimea", img: volimea, href: "https://www.volimea.de/"},
  { name: "Intermatex", img: intermatex, href: "https://www.intermatex.com/areas/home.php"},
  { name: "Sicis", img: sicis, href: "https://www.sicis.com/en_gb/"},
  { name: "Kiesel", img: kiesel, href: "http://www.kiesel.com/start0.html"},
  { name: "Gutjahr", img: gutjahr, href: "https://www.gutjahr.com/en/home-page.html"},
];

function Logobar({ brandNames }) {
  if (!brandNames) {
    return null;
  }

  const brands = BRANDS.filter((brand) => brandNames.indexOf(brand.name.toLowerCase()) !== -1);

  return (
    <div className="mt-4">
      <h3 className="font-size-18 font-size-md-24 font-weight-bold text-warning text-zilla-slab mb-0">
        Mit diesen <span className="font-size-36 font-size-md-48">Marken</span>{" "}
        arbeiten&nbsp;wir:
      </h3>

      <div className="row logobar rounded mx-0">
        {brands.map(brand => (
          <a
            key={brand.name}
            href={brand.href}
            className="brand bg-dark h-80px d-flex justify-content-center align-items-center rounded mr-md-2 mb-2 px-3"
            target="_blank"
          >
            <img
              src={brand.img}
              width={105}
              height={40}
              className="object-fit-contain"
              alt={brand.name}
            />
          </a>
        ))}
      </div>
    </div>
  );
}

export default Logobar;
