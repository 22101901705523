import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Element } from "react-scroll";

import AccordionItem from "./AccordionItem";

class Accordion extends Component {
  state = {
    activeSection: null,
    sections: {
      0: "feinstein",
      1: "marmor_granit",
      2: "volimea_wandbeschichtung",
      3: "mosaik"
    },
    activeCol: null,
    cols: {
      0: "wohnbereich",
      1: "bader",
      2: "bodenbelage",
      3: "schwimmbader",
      4: "vorteile",
      5: "farbtone",
      6: "oberflachentechniken",
      7: "beispiele"
    },
    rowCols: {
      feinstein: ["wohnbereich", "bader", "bodenbelage", "schwimmbader"],
      marmor_granit: ["wohnbereich", "bader", "bodenbelage", "schwimmbader"],
      volimea_wandbeschichtung: ["vorteile", "farbtone", "oberflachentechniken"],
      mosaik: ["vorteile", "beispiele"]
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      !(
        Object.keys(this.state.sections).some(el =>
          nextProps.location.pathname.includes(this.state.sections[el])
        ) || nextProps.location.pathname === "/"
      )
    )
      this.setState({ activeSection: null });

    if (nextProps.location.pathname === "/")
      this.setState({ activeSection: null, activeCol: null });
  }

  componentDidMount() {
    if (
      Object.keys(this.state.sections).some(el =>
        this.props.location.pathname.includes(this.state.sections[el])
      )
    ) {
      let pathArr = this.props.location.pathname.split("/").filter(el => el);
      let section = Object.keys(this.state.sections).filter(
        i => this.state.sections[i] === pathArr[0]
      )[0];
      let col = Object.keys(this.state.cols).filter(
        i => this.state.cols[i] === pathArr[1]
      )[0];

      this.setState({
        activeSection: Number(section),
        activeCol: Number(col)
      });
    }
  }

  changeActive = (e, id) => {
    e && e.preventDefault();
    this.state.activeSection === id
      ? this.setState({ activeSection: null, activeCol: null })
      : this.setState({ activeSection: id, activeCol: null });
  };

  changeActiveState = id => {
    if (this.state.activeSection != null) {
      this.setState({ activeCol: id });
    }
  };

  isVerticalActive = id =>
    this.state.activeCol === id && this.state.activeSection !== null;

  render() {
    const accRoute =
      Object.keys(this.state.sections).some(el =>
        this.props.location.pathname.includes(this.state.sections[el])
      ) || this.props.location.pathname === "/";

    const activeCols = this.state.sections[this.state.activeSection]
      ? this.state.rowCols[this.state.sections[this.state.activeSection]]
      : ["wohnbereich", "bader", "bodenbelage", "schwimmbader"];

    const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

    return (
      <div className="row accordion-how-it-works no-gutters mx-0 w-100 my-2 mt-sm-4 mb-sm-1">
        <Element name="accordion-header" className="w-100">
          <h3 className="font-size-18 font-size-md-24 font-weight-bold text-warning text-zilla-slab mb-0 px-4">
            So <span className="font-size-36 font-size-md-48">arbeiten</span>{" "}
            wir:
          </h3>
        </Element>

        <div className="col-md-6 d-flex flex-column mb-2px mb-md-0">
          <AccordionItem
            text={"Feinstein"}
            active={this.state.activeSection === 0}
            changeActive={e => this.changeActive(e, 0)}
          />
          <AccordionItem
            text={"Marmor & Granit"}
            active={this.state.activeSection === 1}
            changeActive={e => this.changeActive(e, 1)}
          />
          <AccordionItem
            text={"Volimea\u2122 Wandbeschichtung"}
            active={this.state.activeSection === 2}
            changeActive={e => this.changeActive(e, 2)}
          />
          <AccordionItem
            text={"Mosaik"}
            active={this.state.activeSection === 3}
            changeActive={e => this.changeActive(e, 3)}
          />
        </div>

        <Element name="accordion-how-it-works-2" className="col-md-6 d-flex">
          {activeCols.map((row) => {
            let i = Object.values(this.state.cols).indexOf(row);
            return (<AccordionItem
              key={i}
              vertical
              text={capitalize(
                row === "bader"
                  ? "bäder"
                  : row === "bodenbelage"
                  ? "bodenbeläge"
                  : row === "schwimmbader"
                  ? "schwimmbäder"
                  : row === "farbtone"
                  ? "farbtöne"
                  : row === "oberflachentechniken"
                  ? "oberflächentechniken"
                  : row
              )}
              active={this.isVerticalActive(i) && accRoute}
              changeActive={() => this.changeActiveState(i)}
              href={
                this.state.activeSection !== null
                  ? `/${this.state.sections[this.state.activeSection]}/${row}`
                  : "/"
              }
              first={i === 0}
            />);
          })}
        </Element>
      </div>
    );
  }
}

export default withRouter(Accordion);
