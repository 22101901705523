import React from "react";

import GridSmallTextRight from "../GridSmallTextRight";
import LogoBar from "../../Logobar";

import img1 from "../../../static/images/route-screen/marmor/wohnbereich/marmor-wohnbereich-1.png";
import img2 from "../../../static/images/route-screen/marmor/wohnbereich/marmor-wohnbereich-2.png";
import img3 from "../../../static/images/route-screen/marmor/wohnbereich/marmor-wohnbereich-3.png";
import img4 from "../../../static/images/route-screen/marmor/wohnbereich/marmor-wohnbereich-4.png";
import img5 from "../../../static/images/route-screen/marmor/wohnbereich/marmor-wohnbereich-5.png";
import img6 from "../../../static/images/route-screen/marmor/wohnbereich/marmor-wohnbereich-6.png";
import img7 from "../../../static/images/route-screen/marmor/wohnbereich/marmor-wohnbereich-7.png";
import img8 from "../../../static/images/route-screen/marmor/wohnbereich/marmor-wohnbereich-8.png";
import img9 from "../../../static/images/route-screen/marmor/wohnbereich/marmor-wohnbereich-9.png";

function MarmorWohnbereich() {
  return (
    <>
      <GridSmallTextRight
        img1={img1}
        img2={img2}
        img3={img3}
        img4={img4}
        img5={img5}
        img6={img6}
        img7={img7}
        img8={img8}
        img9={img9}
      >
        <span className="mb-4">Naturstein verleiht Ihrem Projekt einen hochwertigen und natürlichen Charakter und eignet sich besonders in anspruchsvollen Situationen.</span>
        <span>Wir sind spezialisiert auf die Verarbeitung von Marmor, Kalkstein und Granit und fühlen uns auch bei großformatigen Platten bis zu 120 x 240 cm wohl.</span>
      </GridSmallTextRight>

      <LogoBar
        brandNames={["tercocer", "wohnrausch", "kiesel", "gutjahr"]}
      />
    </>
  );
}

export default MarmorWohnbereich;
