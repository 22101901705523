import React from "react";

function GridSmallText(props) {
  let { img1, img2, img3, img4, img5, img6, img7, img8, img9 } = props;

  return (
    <div>
      <h3 className="font-size-18 font-size-md-24 font-weight-bold text-warning text-zilla-slab mb-0 px-4 px-xl-0">
        Ein paar <span className="font-size-36 font-size-md-48">Beispiele</span>{" "}
        unserer&nbsp;Arbeit:
      </h3>

      <div className="grid-small-text-right bg-dark rounded overflow-hidden">
        <div>
          {img1 ? <img src={img1} alt="" className="w-100 h-100" /> : null}
        </div>
        <div>
          {img2 ? <img src={img2} alt="" className="w-100 h-100" /> : null}
        </div>
        <div>
          {img3 ? <img src={img3} alt="" className="w-100 h-100" /> : null}
        </div>
        <div>
          {img4 ? <img src={img4} alt="" className="w-100 h-100" /> : null}
        </div>

        <div className="span-2-1">
          {img5 ? <img src={img5} alt="" className="w-100 h-100" /> : null}
        </div>

        <div className="span-2-2 d-flex flex-column justify-content-center align-items-center p-5 lh-2">
          {props.children}
        </div>

        <div>
          {img6 ? <img src={img6} alt="" className="w-100 h-100" /> : null}
        </div>
        <div>
          {img7 ? <img src={img7} alt="" className="w-100 h-100" /> : null}
        </div>
        <div>
          {img8 ? <img src={img8} alt="" className="w-100 h-100" /> : null}
        </div>
        <div>
          {img9 ? <img src={img9} alt="" className="w-100 h-100" /> : null}
        </div>
      </div>
    </div>
  );
}

export default GridSmallText;
