import React from "react";

function Impressum() {
  return (
    <div>
      <h3 className="font-size-36 font-size-md-48 font-weight-bold text-warning text-zilla-slab mb-0 px-4 px-xl-0">
        Markus Greiner
      </h3>
      
      <div className="bg-dark rounded lh-2 py-5 px-4">
        <p className="px-3 mb-4">
          Fachbetrieb für Naturstein, Fliesen und Keramik <br />
          Lambrechter Straße 39 <br />
          67473 Lindenberg
        </p>
        
        <p className="px-3 mb-4">
          Telefon: 06325 98 08-22 <br />
          E-Mail: info@greiner-fliesen.de
        </p>
        
        <p className="px-3 mb-4">
          Zuständige Kammer: <br />
          Handwerkskammer der Pfalz, Am Altenhof 15, 67655 Kaiserslautern
        </p>
        
        <p className="px-3 mb-4">
          Berufsbezeichnung: <br />
          Fliesenleger (verliehen in der Bundesrepublik Deutschland)
        </p>
        
        <p className="px-3 mb-4">
          Berufsrechtliche Regelungen: <br />
          Handwerksordnung: http://bundesrecht.juris.de/bundesrecht/hwo/
        </p>
        
        <p className="px-3 mb-4">
          Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: <br />
          DE 240 33 43 43
        </p>
        
        <p className="px-3 mb-lg-5 pb-2">
          Haftungshinweis: <br />
          Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
        </p>
      </div>
    </div>
  );
}

export default Impressum;
