import React from "react";

import GridImages from "../GridImages";
import LogoBar from "../../Logobar";

import img1 from "../../../static/images/route-screen/mosaik/beispiele/mosaik-beispiele-1.png";
import img2 from "../../../static/images/route-screen/mosaik/beispiele/mosaik-beispiele-2.png";
import img3 from "../../../static/images/route-screen/mosaik/beispiele/mosaik-beispiele-3.png";
import img4 from "../../../static/images/route-screen/mosaik/beispiele/mosaik-beispiele-4.png";
import img5 from "../../../static/images/route-screen/mosaik/beispiele/mosaik-beispiele-5.png";
import img6 from "../../../static/images/route-screen/mosaik/beispiele/mosaik-beispiele-6.png";
import img7 from "../../../static/images/route-screen/mosaik/beispiele/mosaik-beispiele-7.png";
import img8 from "../../../static/images/route-screen/mosaik/beispiele/mosaik-beispiele-8.png";
import img9 from "../../../static/images/route-screen/mosaik/beispiele/mosaik-beispiele-9.png";
import img10 from "../../../static/images/route-screen/mosaik/beispiele/mosaik-beispiele-10.png";

function MosaikBeispiele() {
  return (
    <>
      <GridImages
        img1={img1}
        img2={img2}
        img3={img3}
        img4={img4}
        img5={img5}
        img6={img6}
        img7={img7}
        img8={img8}
        img9={img9}
        img10={img10}
      />

      <LogoBar
        brandNames={["intermatex", "sicis", "kiesel"]}
      />
    </>
  );
}

export default MosaikBeispiele;
