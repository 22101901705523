import React from "react";

import GridSmallTextRight from "../GridSmallTextRight";
import LogoBar from "../../Logobar";

import img1 from "../../../static/images/route-screen/feinstein/bader/feinstein-bader-1.png";
import img2 from "../../../static/images/route-screen/feinstein/bader/feinstein-bader-2.png";
import img3 from "../../../static/images/route-screen/feinstein/bader/feinstein-bader-3.png";
import img4 from "../../../static/images/route-screen/feinstein/bader/feinstein-bader-4.png";
import img5 from "../../../static/images/route-screen/feinstein/bader/feinstein-bader-5.png";
import img6 from "../../../static/images/route-screen/feinstein/bader/feinstein-bader-6.png";
import img7 from "../../../static/images/route-screen/feinstein/bader/feinstein-bader-7.png";
import img8 from "../../../static/images/route-screen/feinstein/bader/feinstein-bader-8.png";
import img9 from "../../../static/images/route-screen/feinstein/bader/feinstein-bader-9.png";

function FeinsteinBader() {
  return (
    <>
      <GridSmallTextRight
        img1={img1}
        img2={img2}
        img3={img3}
        img4={img4}
        img5={img5}
        img6={img6}
        img7={img7}
        img8={img8}
        img9={img9}
      >
        Wir betreuen die Entwicklung Ihres Bades von der 3D-Planung, über die Vorarbeiten bis hin zur Verlegung sämtlicher Fliesen und Natursteine. Und falls Sie noch unentschlossen sind, helfen wir Ihnen gerne auch bei der Wahl kompetenter Partner im Installationsgewerk.
      </GridSmallTextRight>

      <LogoBar
        brandNames={["mirage", "saloni", "serenissima", "cercom", "cir", "kiesel", "gutjahr"]}
      />
    </>
  );
}

export default FeinsteinBader;
