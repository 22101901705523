import React from "react";

function Jobs() {
  return (
    <div>
      <h3 className="font-size-36 font-size-md-48 font-weight-bold text-warning text-zilla-slab mb-0 px-4 px-xl-0">
        Lorem Ipsum
      </h3>
      
      <div className="bg-dark rounded lh-2 py-5 px-4">
        <p className="px-3 mb-4">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto doloremque eligendi fugiat iste quis quisquam. Alias corporis deserunt dolor enim exercitationem magni maxime, minus modi nam quae, sapiente sequi, tempora: <br />
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur culpa dolorem ducimus, et eveniet ex, fuga impedit in incidunt magni minus, modi nulla numquam quaerat quibusdam sit tenetur voluptas voluptates.
        </p>
        
        <p className="px-3 mb-lg-5 pb-2">
          Haftungshinweis: <br />
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi beatae blanditiis consectetur consequuntur cupiditate excepturi fugit in incidunt minima modi molestiae nisi odit, officiis quam recusandae sed sint, suscipit temporibus.
        </p>
      </div>
    </div>
  );
}

export default Jobs;
