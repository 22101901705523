import React from "react";

import GridSmallTextRight from "../GridSmallTextRight";
import LogoBar from "../../Logobar";

import img1 from "../../../static/images/route-screen/feinstein/bodenbelage/feinstein-bodenbelage-1.png";
import img2 from "../../../static/images/route-screen/feinstein/bodenbelage/feinstein-bodenbelage-2.png";
import img3 from "../../../static/images/route-screen/feinstein/bodenbelage/feinstein-bodenbelage-3.png";
import img4 from "../../../static/images/route-screen/feinstein/bodenbelage/feinstein-bodenbelage-4.png";
import img5 from "../../../static/images/route-screen/feinstein/bodenbelage/feinstein-bodenbelage-5.png";
import img6 from "../../../static/images/route-screen/feinstein/bodenbelage/feinstein-bodenbelage-6.png";
import img7 from "../../../static/images/route-screen/feinstein/bodenbelage/feinstein-bodenbelage-7.png";
import img8 from "../../../static/images/route-screen/feinstein/bodenbelage/feinstein-bodenbelage-8.png";
import img9 from "../../../static/images/route-screen/feinstein/bodenbelage/feinstein-bodenbelage-9.png";

function FeinsteinBodenbelage() {
  return (
    <>
      <GridSmallTextRight
        img1={img1}
        img2={img2}
        img3={img3}
        img4={img4}
        img5={img5}
        img6={img6}
        img7={img7}
        img8={img8}
        img9={img9}
      >
        Feinsteinzeug bietet Ihnen eine herrliche Vielfalt an Farben, Oberflächen und Formaten. Von kostensparenden Standardformaten bis hin zu handgefertigten Unikaten in 120 x 240 cm, wir pflegen enge Kontakte zu den besten Herstellern im Markt und helfen Ihnen gerne, den passenden Belag für jeden Raum zu finden.
      </GridSmallTextRight>

      <LogoBar
        brandNames={["mirage", "saloni", "serenissima", "cercom", "cir", "kiesel", "gutjahr"]}
      />
    </>
  );
}

export default FeinsteinBodenbelage;
