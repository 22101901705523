import React, { Component } from "react";
import { scroller } from "react-scroll";
import arrow from "../static/images/icons/arrow-top.png";

class ToTop extends Component {
  scrollTop = e => {
    e.preventDefault();

    scroller.scrollTo("accordion-header", {
      offset: -4,
      duration: 500,
      smooth: true
    });
  };

  render() {
    return (
      <div className="d-flex d-xl-none mt-4 mb-5">
        <a
          href="#"
          onClick={this.scrollTop}
          className="position-relative d-inline-flex justify-content-center align-items-center bg-warning rounded-circle ml-auto mr-4"
          style={{ width: 48, height: 48 }}
        >
          <img src={arrow} alt="Arrow" width={20} />
        </a>
      </div>
    );
  }
}

export default ToTop;
