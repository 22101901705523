import React, { Component } from "react";
import { Link } from "react-router-dom";

import rectangle from "../../static/images/icons/rectangle.svg";
import { scroller } from "react-scroll";

class AccordionItem extends Component {
  handleItemClick = () => {
    if (window.innerWidth < 1024) {
      scroller.scrollTo(
        this.props.vertical
          ? "route-screen-container"
          : "accordion-how-it-works-2",
        {
          duration: 500,
          smooth: true
        }
      );
    }

    this.props.changeActive();
  };

  render() {
    const { text, vertical, active, href, last, first } = this.props;

    const rectangles = [
      <img key={1} src={rectangle} width={16} className="rect-1" alt="" />,
      <img key={2} src={rectangle} width={32} className="rect-2" alt="" />,
      <img key={3} src={rectangle} width={16} className="rect-3" alt="" />,
      <img key={4} src={rectangle} width={32} className="rect-4" alt="" />,
      <img key={5} src={rectangle} width={10} className="rect-5" alt="" />,
      <img key={6} src={rectangle} width={24} className="rect-6" alt="" />
    ];

    return !vertical ? (
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          this.handleItemClick();
        }}
        className={`accordion-item d-flex align-items-center justify-content-center
      bg-dark flex-1-1 rounded position-relative ${!last ? "mb-2px " : ""}${
          active ? " active" : ""
        }`}
      >
        <span className="position-relative z-index-1">{text}</span>

        {rectangles}
      </a>
    ) : (
      <Link
        to={href}
        onClick={this.handleItemClick}
        className={`accordion-item d-flex align-items-center justify-content-center 
      bg-dark flex-1-1 rounded position-relative text-vertical
      ${first ? "ml-0 ml-md-2px " : "ml-2px"}
      ${active ? " active" : ""}`}
      >
        <span className="position-relative z-index-1">{text}</span>

        {rectangles}
      </Link>
    );
  }
}

export default AccordionItem;
