import React from "react";

import GridLargeTextRight from "../GridLargeTextRight";
import LogoBar from "../../Logobar";

import img1 from "../../../static/images/route-screen/volimea/oberflachentechniken/volimea-oberflachentechniken-1.png";
import img2 from "../../../static/images/route-screen/volimea/oberflachentechniken/volimea-oberflachentechniken-2.png";
import img3 from "../../../static/images/route-screen/volimea/oberflachentechniken/volimea-oberflachentechniken-3.png";
import img4 from "../../../static/images/route-screen/volimea/oberflachentechniken/volimea-oberflachentechniken-4.png";
import img5 from "../../../static/images/route-screen/volimea/oberflachentechniken/volimea-oberflachentechniken-5.png";

function VolimeaOberflachentechniken() {
  return (
    <>
      <GridLargeTextRight
        img1={img1}
        img2={img2}
        img3={img3}
        img4={img4}
        img5={img5}
      >
        <span className="mb-4">Volimea&trade; ist nicht nur wegen seines breiten Farbspektrums ein wirklich außergewöhnliches Material. Die kreativen Möglichkeiten in dem Verarbeiten potenzieren diese Vielfältigkeit noch ein Mal.</span>
        <span>Ob taupe-farben mit Lederoptik, weiß glatt verspachtelt mit Goldglimmer, Bleigrau mit Moskauer Glas oder in Ihrer ganz persönlichen Kombination: wir helfen Ihnen aus einer einfachen Wand einen echten Blickfang zu machen.</span>
      </GridLargeTextRight>

      <LogoBar
        brandNames={["volimea", "kiesel"]}
      />
    </>
  );
}

export default VolimeaOberflachentechniken;
