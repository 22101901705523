import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Content from "./Content";
import ToTop from "./components/ToTop";
import "./scss/styles.scss";
import "./App.scss";

function App() {
  return (
    <Router>
      <Content />

      <ToTop />
    </Router>
  );
}

export default App;
