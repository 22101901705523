import React from "react";

import GridSmallTextRight from "../GridSmallTextRight";
import LogoBar from "../../Logobar";

import img1 from "../../../static/images/route-screen/feinstein/schwimmbader/feinstein-schwimmbader-1.png";
import img2 from "../../../static/images/route-screen/feinstein/schwimmbader/feinstein-schwimmbader-2.png";
import img3 from "../../../static/images/route-screen/feinstein/schwimmbader/feinstein-schwimmbader-3.png";
import img4 from "../../../static/images/route-screen/feinstein/schwimmbader/feinstein-schwimmbader-4.png";
import img5 from "../../../static/images/route-screen/feinstein/schwimmbader/feinstein-schwimmbader-5.png";
import img6 from "../../../static/images/route-screen/feinstein/schwimmbader/feinstein-schwimmbader-6.png";
import img7 from "../../../static/images/route-screen/feinstein/schwimmbader/feinstein-schwimmbader-7.png";
import img8 from "../../../static/images/route-screen/feinstein/schwimmbader/feinstein-schwimmbader-8.png";
import img9 from "../../../static/images/route-screen/feinstein/schwimmbader/feinstein-schwimmbader-9.png";

function FeinsteinSchwimmbader() {
  return (
    <>
        <GridSmallTextRight
        img1={img1}
        img2={img2}
        img3={img3}
        img4={img4}
        img5={img5}
        img6={img6}
        img7={img7}
        img8={img8}
        img9={img9}
        >
            <span className="mb-4">Ob außen oder innen, funktional oder edel, Sie können sich sicher sein, dass Ihr Pool bei uns in besten Händen ist.</span>
            <span>Wir kümmern uns gerne auch um die Estricharbeiten und die Abdichtung, damit Sie lange Spaß und Freude an Ihrem Schwimmbad haben.</span>
        </GridSmallTextRight>

        <LogoBar
          brandNames={["mirage", "saloni", "serenissima", "cercom", "cir", "kiesel", "gutjahr"]}
        />
    </>
  );
}

export default FeinsteinSchwimmbader;
