import React from "react";

import GridSmallTextRight from "../GridSmallTextRight";
import LogoBar from "../../Logobar";

import img1 from "../../../static/images/route-screen/marmor/bodenbelage/marmor-bodenbelage-1.png";
import img2 from "../../../static/images/route-screen/marmor/bodenbelage/marmor-bodenbelage-2.png";
import img3 from "../../../static/images/route-screen/marmor/bodenbelage/marmor-bodenbelage-3.png";
import img4 from "../../../static/images/route-screen/marmor/bodenbelage/marmor-bodenbelage-4.png";
import img5 from "../../../static/images/route-screen/marmor/bodenbelage/marmor-bodenbelage-5.png";
import img6 from "../../../static/images/route-screen/marmor/bodenbelage/marmor-bodenbelage-6.png";
import img7 from "../../../static/images/route-screen/marmor/bodenbelage/marmor-bodenbelage-7.png";
import img8 from "../../../static/images/route-screen/marmor/bodenbelage/marmor-bodenbelage-8.png";
import img9 from "../../../static/images/route-screen/marmor/bodenbelage/marmor-bodenbelage-9.png";

function MarmorBodenbelage() {
  return (
    <>
      <GridSmallTextRight
        img1={img1}
        img2={img2}
        img3={img3}
        img4={img4}
        img5={img5}
        img6={img6}
        img7={img7}
        img8={img8}
        img9={img9}
      >
        <span className="mb-4">Natursteinböden mit ihrer gewachsenen Struktur bieten Ihnen eine einzigartige und zeitlose Ästhetik.</span>
        <span>Unsere modernen Verarbeitungsmethoden machen sie zudem pflegeleicht und vielseitig verwendbar.</span>
      </GridSmallTextRight>

      <LogoBar
        brandNames={["tercocer", "wohnrausch", "kiesel", "gutjahr"]}
      />
    </>
  );
}

export default MarmorBodenbelage;
