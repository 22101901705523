import React from "react";

import GridLargeTextRight from "../GridLargeTextRight";
import LogoBar from "../../Logobar";

import img1 from "../../../static/images/route-screen/volimea/farbtone/volimea-farbtone-1.png";
import img2 from "../../../static/images/route-screen/volimea/farbtone/volimea-farbtone-2.png";
import img3 from "../../../static/images/route-screen/volimea/farbtone/volimea-farbtone-3.png";
import img4 from "../../../static/images/route-screen/volimea/farbtone/volimea-farbtone-4.png";
import img5 from "../../../static/images/route-screen/volimea/farbtone/volimea-farbtone-5.png";

function VolimeaFarbtone() {
  return (
    <>
      <GridLargeTextRight
        img1={img1}
        img2={img2}
        img3={img3}
        img4={img4}
        img5={img5}
      >
        <span className="mb-4">Volimea&trade; bietet Ihnen eine riesige Palette von mehr als zweihundert möglichen Farb- und Veredelungskombinationen.</span>
        <span>Damit Sie bei so vielen Möglichkeiten nicht die Orientierung verlieren, können wir Ihnen gerne Muster zum Vergleichen vor Ort und zur Auswahl mitgeben.</span>
      </GridLargeTextRight>

      <LogoBar
        brandNames={["volimea", "kiesel"]}
      />
    </>
  );
}

export default VolimeaFarbtone;
