import React from "react";

import rectangle from "../static/images/icons/rectangle.svg";

function BgRectangles() {
  return (
    <>
      <img src={rectangle} width={16} className="rect-1" alt=""/>
      <img src={rectangle} width={32} className="rect-2" alt=""/>
      <img src={rectangle} width={16} className="rect-3" alt=""/>
      <img src={rectangle} width={32} className="rect-4" alt=""/>
    </>
  )
}

export default BgRectangles;
