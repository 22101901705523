import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import MobileHeaderModal from "./MobileHeaderModal";

import BgRectangles from "./BgRectangles";
import greinerImg from "../static/images/logo_greiner.svg";
import greinerImgMobile from "../static/images/logo.svg";
import neustadt from "../static/images/offices/neustadt.png";
import lindenberg from "../static/images/offices/lindenberg.png";
import menuIcon from "../static/images/icons/menu-icon.svg";

class Header extends Component {
  toggleLiActive = e => {
    e.currentTarget.parentNode.parentNode
      .querySelectorAll("li")
      .forEach(li => li.classList.remove("active"));

    e.currentTarget.parentNode.classList.add("active");
  };

  render() {
    const location = this.props.location;

    return (
      <div className="header row no-gutters bg-md-dark rounded-bottom mx-0 ">
        <div className="col-md-6">
          {/* Desktop Header */}
          <div className="row mx-0 d-none d-md-flex">
            <Link
              to={"/"}
              className="col-md-6 d-flex flex-column justify-content-center align-items-center bg-warning rounded-bottom-right py-2"
            >
              <img src={greinerImg} alt="Greiner Logo" className="w-100" />
            </Link>

            <div className="col-md-6 d-flex flex-column justify-content-center text-zilla-slab font-size-22 px-5">
              <p className="text-warning mb-3">Naturstein Fliesen Keramik</p>
              <p className="text-white mb-0">seit&nbsp;2004</p>
            </div>
          </div>

          {/* Mobile Header */}
          <div className="d-flex mx-0 mb-2px bg-dark d-md-none">
            <Link
              to={"/"}
              className="d-flex flex-column justify-content-center align-items-center bg-warning rounded-bottom-left text-dark-grey text-zilla-slab font-weight-bold py-2"
              style={{ height: 103, minWidth: 103 }}
            >
              <img
                src={greinerImgMobile}
                alt="Greiner Logo"
                width={47}
                className="d-block mx-2"
              />
              greiner
            </Link>

            <div className="d-flex flex-column justify-content-center text-zilla-slab font-size-24 px-3">
              <p className="text-warning mb-0">Naturstein Fliesen Keramik</p>
              <p className="text-white mb-0">seit&nbsp;2004</p>
            </div>

            <a
              href="#"
              data-toggle="modal"
              data-target="#modal-mobile-menu"
              className="my-auto ml-auto mr-2"
            >
              <img src={menuIcon} width={33} alt="Menu icon" />
            </a>
          </div>

          {/* Mobile Header Modal */}
          <MobileHeaderModal />

          <div className="bg-dark erreichen rounded py-4 py-md-5 px-4 mb-2px mb-md-0">
            <h3 className="font-size-18 font-size-md-24 font-weight-bold text-warning text-zilla-slab mb-4 pb-1 pb-md-2">
              So <span className="font-size-36 font-size-md-48">erreichen</span>{" "}
              Sie uns:
            </h3>

            <ul className="list-unstyled px-md-3">
              <li className="row align-items-center mx-0 mb-1">
                <div className="col-4 text-warning text-nowrap px-0">E-mail:</div>
                <div className="col-8 pr-0 pl-3 font-size-16 font-size-lg-21">
                  <a href="mailto:info@greiner-fliesen.de" className="d-md-none text-decoration-underline">info@greiner-fliesen.de</a>
                  <a href="mailto:info@greiner-fliesen.de" className="d-none d-md-block">info@greiner-fliesen.de</a>
                </div>
              </li>

              <li className="row align-items-center mx-0 mb-3">
                <div className="col-4 text-warning px-0">Mobil:</div>
                <div className="col-8 pr-0 pl-3 font-size-16 font-size-lg-21">
                  <a href="tel:0172-88-06-523" className="d-md-none text-decoration-underline">0172 88 06 523</a>
                  <span className="d-none d-md-block">0172 88 06 523</span>
                </div>
              </li>

              <li className="row align-items-center mx-0 mb-1">
                <div className="col-4 text-warning px-0">Lindenberg:</div>
                <div className="col-8 pr-0 pl-3 font-size-16 font-size-lg-21">
                  <a href="tel:06325-980-822" className="d-md-none text-decoration-underline">06325 - 980 822</a>
                  <span className="d-none d-md-block">06325 - 980 822</span>
                </div>
              </li>

              <li className="row align-items-center mx-0 mb-1">
                <div className="col-4 text-warning px-0">Neustadt:</div>
                <div className="col-8 pr-0 pl-3 font-size-16 font-size-lg-21">
                  <a href="tel:06321-968-18-21" className="d-md-none text-decoration-underline">06321 - 968 18 21</a>
                  <span className="d-none d-md-block">06321 - 968 18 21</span>
                </div>
              </li>

              <li className="row align-items-center mx-0">
                <div className="col-4 text-warning px-0">Fax für Neustadt:</div>
                <div className="col-8 pr-0 pl-3 font-size-16 font-size-lg-21">
                  <a href="tel:06321-968-18-22" className="d-md-none text-decoration-underline">06321 - 968 18 22</a>
                  <span className="d-none d-md-block">06321 - 968 18 22</span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-md-6 d-flex flex-column header-right">
          <ul className="nav nav-top-tabs row text-center no-gutters mx-0 d-none d-md-flex">
            <li className="nav-item border-left col-4 overflow-hidden">
              <Link
                to="/"
                className={`nav-link d-flex justify-content-center align-items-center text-secondary h-80px ${
                  location.pathname === "/jobs/" ? "active" : ""
                }`}
              >
                <span className="position-relative z-index-1">Stellenanzeigen</span>
                <BgRectangles />
              </Link>
            </li>

            <li className="nav-item border-left col-4 overflow-hidden">
              <Link
                to="/impressum/"
                className={`nav-link d-flex justify-content-center align-items-center text-secondary h-80px ${
                  location.pathname === "/impressum/" ? "active" : ""
                }`}
              >
                <span className="position-relative z-index-1">Impressum</span>
                <BgRectangles />
              </Link>
            </li>

            <li className="nav-item border-left col-4 overflow-hidden">
              <Link
                to="/datenschutz/"
                className={`nav-link d-flex justify-content-center align-items-center text-secondary h-80px ${
                  location.pathname === "/datenschutz/" ? "active" : ""
                }`}
              >
                <span className="position-relative z-index-1">Datenschutz</span>
                <BgRectangles />
              </Link>
            </li>
          </ul>

          <div className="position-relative flex-1-1 overlay-gradient">
            <div className="tab-content" id="myTabContent">
              {/* First tab */}
              <div
                className="tab-pane fade show active"
                id="tab-1"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <img src={neustadt} alt="Neustadt" className="w-100 h-100" />
              </div>

              {/* Second tab */}
              <div
                className="tab-pane fade"
                id="tab-2"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <img src={lindenberg} alt="Lindenberg" className="w-100 h-100" />
              </div>
            </div>

            <h3 className="font-size-18 font-size-md-24 font-weight-bold text-warning text-zilla-slab position-absolute bottom-0 left-0 z-index-1 mb-0 px-4 px-md-0">
              Hier <span className="font-size-36 font-size-md-48">finden</span>{" "}
              Sie uns:
            </h3>
          </div>

          {/* Nav tabs */}
          <ul className="row nav nav-bottom-tabs nav-tabs no-gutters font-size-13 font-size-sm-15 font-size-md-16 border-bottom-0 mx-0">
            <li className="col-6 nav-item active">
              <a
                className="d-flex flex-column justify-content-center align-items-center active h-100 px-1 py-4 px-md-4"
                data-toggle="tab"
                href="#tab-1"
                role="tab"
                onClick={this.toggleLiActive}
              >
                <div className="position-relative z-index-1">
                  <p className="d-flex justify-content-center font-weight-bold mt-2 mb-4 pt-2">
                    Fliesenatelier Neustadt
                  </p>

                  <p className="mb-2">
                    Nachtweide 3a
                    <br />
                    67433 Neustadt
                  </p>
                </div>

                <div className="position-absolute overflow-hidden h-100 w-100">
                  <BgRectangles />
                </div>
              </a>
            </li>

            <li className="col-6 nav-item">
              <a
                className="d-flex flex-column justify-content-center align-items-center h-100 px-1 py-4 px-md-4"
                data-toggle="tab"
                href="#tab-2"
                role="tab"
                onClick={this.toggleLiActive}
              >
                <div className="position-relative z-index-1">
                  <p className="d-flex justify-content-center font-weight-bold mt-2 mb-4 pt-2">
                    Fliesenatelier Lindenberg
                  </p>

                  <p className="mb-2">
                    Lambrechter Straße 39
                    <br />
                    67473 Lindenberg
                  </p>
                </div>

                <div className="position-absolute overflow-hidden h-100 w-100">
                  <BgRectangles />
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
