import React from "react";

import GridLargeTextRight from "../GridLargeTextRight";
import LogoBar from "../../Logobar";

import img1 from "../../../static/images/route-screen/volimea/vorteile/volimea-vorteile-1.png";
import img2 from "../../../static/images/route-screen/volimea/vorteile/volimea-vorteile-2.png";
import img3 from "../../../static/images/route-screen/volimea/vorteile/volimea-vorteile-3.png";
import img4 from "../../../static/images/route-screen/volimea/vorteile/volimea-vorteile-4.png";
import img5 from "../../../static/images/route-screen/volimea/vorteile/volimea-vorteile-5.png";

function VolimeaVorteile() {
  return (
    <>
      <GridLargeTextRight
        img1={img1}
        img2={img2}
        img3={img3}
        img4={img4}
        img5={img5}
      >
        <span className="mb-4">Volimea&trade; ist unser Alleskönner für den Innenbereich. Der pigmentierte Marmor-Kalk-Putz verstrahlt nicht nur Eleganz und ein optimales Wohlfühlambiente, sondern ist in eine Vielzahl von Farben verfügbar und bietet auch sonst nur Vorteile.</span>
        <span>Ihre Wand bleibt diffusionsoffen, unbrennbar und unser ökologischer Putz hilft auch noch bei der Feuchtigkeitsregulierung - ein echter Tausendsassa!</span>
      </GridLargeTextRight>

      <LogoBar
        brandNames={["volimea", "kiesel"]}
      />
    </>
  );
}

export default VolimeaVorteile;
