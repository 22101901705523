import React, { Component } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

import greinerImg from "../static/images/logo_greiner.svg";
import xIcon from "../static/images/icons/x-icon-black.svg";

class MobileHeaderModal extends Component {
  closeModal = React.createRef();

  handleRouteChange = e => {
    this.closeModal.current.click();

    scroll.scrollToTop();
  };

  render() {
    return (
      <div
        className="modal fade"
        id="modal-mobile-menu"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog mt-0 ml-2px mr-2px"
          role="document"
          style={{ transform: "none", maxWidth: "none" }}
        >
          <div className="modal-content bg-transparent border-0">
            <div className="row mx-0 mb-2px bg-warning rounded-bottom">
              <div
                className="col-auto d-flex flex-column justify-content-center align-items-center rounded-bottom-left text-dark-grey"
                style={{ height: 103, minWidth: 103 }}
              >
                <img
                  src={greinerImg}
                  alt="Greiner Logo"
                  width={125}
                  className="d-block"
                  style={{ marginBottom: 16, marginLeft: 13 }}
                />
              </div>

              <div className="col-auto my-auto ml-auto mr-2">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{ opacity: 1, marginRight: 10 }}
                  ref={this.closeModal}
                >
                  <img src={xIcon} width={24} alt="X icon" />
                </button>
              </div>
            </div>

            <ul
              className="rounded-bottom list-unstyled font-weight-bold text-zilla-slab font-size-28 px-4 py-4 mx-3"
              style={{ backgroundColor: "#222" }}
            >
              <li className="py-3">
                <Link
                  to="/impressum/"
                  className="text-warning p-2"
                  onClick={this.handleRouteChange}
                >
                  Impressum
                </Link>
              </li>

              <li className="py-3 mb-2" onClick={this.handleRouteChange}>
                <Link to="/datenschutz/" className="text-warning p-2">
                  Datenschutz
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileHeaderModal;
