import React from "react";
import { withRouter } from "react-router-dom";

import Header from "./components/Header";
import Accordion from "./components/Accordion/Accordion";
import RouteScreenContainer from "./components/RouteScreenContainer";

function Content({location}) {
  return (
    <div
      className={`row mx-0 ${
        ["/jobs/", "/impressum/", "/datenschutz/"].includes(location.pathname)
          ? "location-tabs"
          : ""
      }`}
    >
      <div className="col-xl-6 px-0 pr-xl-4 pl-xl-0">
        <Header />

        <Accordion />
      </div>

      <div className="col-xl-6 px-0 pl-xl-4 pr-xl-0">
        <RouteScreenContainer />
      </div>
    </div>
  );
}

export default withRouter(Content);
