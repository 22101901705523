import React from "react";

import img1 from "../../static/images/route-screen/feinstein/wohnbereich/feinstein-wohnbereich-5.png";
import img2 from "../../static/images/route-screen/volimea/oberflachentechniken/volimea-oberflachentechniken-1.png";
import img3 from "../../static/images/route-screen/feinstein/bodenbelage/feinstein-bodenbelage-5.png";
import img4 from "../../static/images/route-screen/feinstein/wohnbereich/feinstein-wohnbereich-8.png";
import img5 from "../../static/images/route-screen/volimea/farbtone/volimea-farbtone-3.png";
import img6 from "../../static/images/route-screen/volimea/oberflachentechniken/volimea-oberflachentechniken-1.png";
import img7 from "../../static/images/route-screen/feinstein/wohnbereich/feinstein-wohnbereich-7.png";
import img8 from "../../static/images/route-screen/feinstein/wohnbereich/feinstein-wohnbereich-3.png";

function Default() {
  return (
      <div>
        <h3 className="font-size-18 font-size-md-24 font-weight-bold text-warning text-zilla-slab mb-0 px-4 px-xl-0">
            Über <span className="font-size-36 font-size-md-48">Greiner-Fliesen</span>
        </h3>
        <div className="route-screen-grid-default bg-dark rounded overflow-hidden">
            <div>
                {img1 ? <img src={img1} alt="" className="w-100 h-100" /> : null}
            </div>
            <div>
                {img2 ? <img src={img2} alt="" className="w-100 h-100" /> : null}
            </div>
            <div>
                {img3 ? <img src={img3} alt="" className="w-100 h-100" /> : null}
            </div>
            <div>
                {img4 ? <img src={img4} alt="" className="w-100 h-100" /> : null}
            </div>

            <div className="text-1 px-5 py-3 lh-2">
                <p className="mb-4">
                    Die Firma Greiner, Fachbetrieb für Naturstein, Fliese und Keramik hat sich auf Grund der großen Nachfrage nach positiven 15 Jahren dafür entschieden, in der Nachtweide 3a in 67433 Neustadt eine Zweigstelle mit größerer Ausstellung aufzubauen.
                    <br/>Zu unserem Team gehören zur Zeit 8 Angestellte und 2 Auszubildende.
                </p>
                <div className="mb-4">
                    Zu unseren fachlichen Kompetenzen zählen:
                    <ul>
                        <li>Fliesenhandel für Privat- und Gewerbekunden</li>
                        <li>Fachliche Beratung, Planung und Kostenkalkulation</li>
                        <li>Durchführung sämtlich notwendiger Vorarbeiten</li>
                        <li>Verarbeiten und Verlegen sämtlicher Fliesen, Platten &amp; Mosaikbeläge</li>
                        <li>Verarbeiten und Verlegen von Natursteinplatten</li>
                        <li>Volimea&trade; Wandbeschichtungen</li>
                    </ul>
                </div>
                <p>
                    Entdecken Sie die Vorteile moderner Baumaterialien.
                </p>
            </div>
            
            <div>
                {img5 ? <img src={img5} alt="" className="w-100 h-100" /> : null}
            </div>
            <div>
                {img6 ? <img src={img6} alt="" className="w-100 h-100" /> : null}
            </div>
            <div>
                {img7 ? <img src={img7} alt="" className="w-100 h-100" /> : null}
            </div>
            <div>
                {img8 ? <img src={img8} alt="" className="w-100 h-100" /> : null}
            </div>
        </div>
      </div>
  );
}

export default Default;
