import React from "react";

import GridSmallTextRight from "../GridSmallTextRight";
import LogoBar from "../../Logobar";

import img1 from "../../../static/images/route-screen/feinstein/wohnbereich/feinstein-wohnbereich-1.png";
import img2 from "../../../static/images/route-screen/feinstein/wohnbereich/feinstein-wohnbereich-2.png";
import img3 from "../../../static/images/route-screen/feinstein/wohnbereich/feinstein-wohnbereich-3.png";
import img4 from "../../../static/images/route-screen/feinstein/wohnbereich/feinstein-wohnbereich-4.png";
import img5 from "../../../static/images/route-screen/feinstein/wohnbereich/feinstein-wohnbereich-5.png";
import img6 from "../../../static/images/route-screen/feinstein/wohnbereich/feinstein-wohnbereich-6.png";
import img7 from "../../../static/images/route-screen/feinstein/wohnbereich/feinstein-wohnbereich-7.png";
import img8 from "../../../static/images/route-screen/feinstein/wohnbereich/feinstein-wohnbereich-8.png";
import img9 from "../../../static/images/route-screen/feinstein/wohnbereich/feinstein-wohnbereich-9.png";

function FeinsteinWohnbereich() {
  return (
    <>
      <GridSmallTextRight
        img1={img1}
        img2={img2}
        img3={img3}
        img4={img4}
        img5={img5}
        img6={img6}
        img7={img7}
        img8={img8}
        img9={img9}
      >
        Unsere Fliesen aus Feinsteinzeug sind pflegeleicht und vielseitig in ihrem Erscheinungsbild. Von elegant bis verspielt, von klassisch bis modern und im Format bis zu 240 x 120 cm verfügbar.
      </GridSmallTextRight>

      <LogoBar
        brandNames={["mirage", "saloni", "serenissima", "cercom", "cir", "kiesel", "gutjahr"]}
      />
    </>
  );
}

export default FeinsteinWohnbereich;
